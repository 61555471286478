export const initialState = {
	token: null,
};

export const reducer = (state, action) => {
	switch (action.type) {
		default: {
			return state;
		}
	}
};
