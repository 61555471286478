const Collage = () => {
	return (
		<div className="collage">
			<img
				src="https://firebasestorage.googleapis.com/v0/b/ace-bridge.appspot.com/o/header%2FP155_v2.jpg?alt=media&token=f135ff18-f1ae-4cfb-a348-ddf2aaea5b73"
				alt=""
			/>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
};

export default Collage;
